import React from "react";
import {Grid, LinearProgress} from "@material-ui/core";
import LogoHorizontal from "../LogoHorizontal";

export default function Loading(props) {
    return (
        <Grid style={{height: "100%"}} container direction={"row"} justify={"center"} alignItems={"center"}>
            <Grid item xs={8}>
                <div style={{textAlign: "center", marginBottom: 20}}>
                    <LogoHorizontal width={"80%"}/>
                    <LinearProgress color="secondary" />
                </div>
            </Grid>
        </Grid>
    );
}
