import React from "react";
import { useLocation } from "react-router-dom";
import {Route, Switch} from "react-router";
import {tutorialIsViewed} from "../storage/Tutorial";

const Home = React.lazy(() => import("../pages/Home/"));
const Faq = React.lazy(() => import("../pages/Faq/"));
const Tutorial = React.lazy(() => import("../pages/Tutorial/"));
const References = React.lazy(() => import("../pages/References/"));
const Testimonials = React.lazy(() => import("../pages/Testimonials/"));
const Blog = React.lazy(() => import("../pages/Blog/"));
const About = React.lazy(() => import("../pages/About/"));
const Contact = React.lazy(() => import("../pages/Contact/"));

export default function Routes(props) {
    const location = useLocation();

    if (true !== tutorialIsViewed() && '/tutorial' !== location.pathname) {
        return (
            <Switch>
                <Route path={"/"} exact component={Tutorial}/>
            </Switch>
        )
    }

    return (
        <Switch>
            <Route path={"/"} exact component={Home}/>
            <Route path={"/faq"} exact component={Faq}/>
            <Route path={"/tutorial"} exact component={Tutorial}/>
            <Route path={"/referencias"} exact component={References}/>
            <Route path={"/depoimentos"} exact component={Testimonials}/>
            <Route path={"/blog"} exact component={Blog}/>
            <Route path={"/sobre"} exact component={About}/>
            <Route path={"/contato"} exact component={Contact}/>
        </Switch>
    )
}
