import React, {Suspense} from 'react';
import {BrowserRouter} from "react-router-dom";
import {Router} from "react-router";
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/core/styles';
import Routes from "./config/Routes";
import {theme} from "./config/Theme";
import Loading from "./components/Loading";

function App() {
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Suspense fallback={<Loading/>}>
            <Router history={createBrowserHistory()}>
              <Routes/>
            </Router>
          </Suspense>
        </BrowserRouter>
      </ThemeProvider>
    );
}

export default App;
